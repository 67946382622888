<template>
  <div class="ml-panel-confirming-page h100 d-flex flex-column">
    <div class="pb flex-grow-1">
      <div class="ml-title1-24-32-600 mb-3">Оформление</div>
      <div class="ml-silver-block mb-3">
        <div class="ml-text-16-20 d-flex justify-content-between mb-3">
          <span>Всего сертификатов</span>
          <span>{{ allPositions.count }}</span>
        </div>
        <div class="ml-text-16-20 d-flex justify-content-between">
          <span>Общая стоимость</span>
          <span>{{ allPositions.price }}₽</span>
        </div>
      </div>
      <div class="ml-silver-block mb-3">
        <div class="mb-4 d-flex justify-content-between">
          <span class="ml-text-16-24-600">Контакты отправителя</span>
          <a href="#" @click.prevent="editContacts">
            <img class="" src="~@/assets/img/arrow-right.png" alt="" />
          </a>
        </div>
        <div class="mb-4">
          <div class="ml-text-13-16 ml-text-grey4">Имя</div>
          <div class="ml-text-16-24 ml-text-grey1">{{ contacts.name }}</div>
        </div>
        <div class="mb-4">
          <div class="ml-text-13-16 ml-text-grey4">Номер телефона</div>
          <div class="ml-text-16-24 ml-text-grey1">+7 {{ contacts.phone }}</div>
        </div>
        <div class="mb-4">
          <div class="ml-text-13-16 ml-text-grey4">E-mail</div>
          <div class="ml-text-16-24 ml-text-grey1">{{ contacts.email }}</div>
        </div>
        <div class="mb-4">
          <div class="ml-text-13-16 ml-text-grey4">Канал информирования</div>
          <div class="ml-text-16-24 ml-text-grey1">{{ channel }}</div>
        </div>
      </div>
      <div class="ml-silver-block mb-8" v-if="isShowCard">
        <div class="ml-text-16-24-600 mb-2">Карта лояльности</div>
        <div>
          <v-text-field
            color="dark"
            type="number"
            height="60"
            label="Номер карты лояльности"
            class="ml-input ml-hide-details ml-input-bg-white"
            outlined
            autocomplete="off"
            v-model="loyaltyCard"
          ></v-text-field>
          <p class="ml-text-13-16 mb-0">
            Чтобы получить бонусы за покупку сертификата укажите номер карты
            лояльности BeautyBonus
          </p>
        </div>
      </div>
    </div>
    <div class="controlls" ref="controlls">
      <button
        :disabled="loadingPayment"
        @click.prevent="pay"
        class="ml-black-btn w100"
      >
        Оплатить {{ allPositions.price }} ₽
        <v-progress-circular
          v-if="loadingPayment"
          indeterminate
          width="2"
          size="20"
        ></v-progress-circular>
      </button>
      <p class="text4 mt-3 mb-0">
        Нажимая кнопку "Оплатить", я соглашаюсь с
        <!-- <a href="#" @click.prevent="goOffer">
          <u>Правилами использования</u>
        </a>
        подарочных карт и сертификатов и -->
        <a href="#" @click.prevent="goOffer">
          <u>Офертой.</u>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MixinChangePanelPage from '@/helpers/mixins/panel/changePage'
import MixinObserveElement from '@/helpers/mixins/observeElement'
// import MixinSendMethod from '@/helpers/mixins/sendMethod'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import yookassaTypes from '@/store/yookassa/types'
import verificationTypes from '@/store/verificationCode/types'
import MixinChagePage from '@/helpers/mixins/burger/changePage'
import MixinCommon from '@/helpers/mixins/common'
import { OFFER } from '@/helpers/const/widgetPage'
import panelBurgerTypes from '@/store/panelBurger/types'
import appTypes from '@/store/app/types'
import navTypes from '@/store/navigation/types'
import basketTypes from '@/store/basket/types'

export default {
  mixins: [
    MixinChangePanelPage,
    MixinObserveElement,
    MixinChagePage,
    MixinCommon
  ],
  data: () => ({
    loyaltyCard: null,
    loadingPayment: false
  }),
  computed: {
    ...mapGetters(['verificationCode/isVerified', 'basket/allPositions']),
    ...mapState({
      contacts: state => state.verificationCode.contacts,
      config: state => state?.app?.pageConfirmConfig
    }),
    isShowCard() {
      return this.config?.CardLoyaltyShown
    },
    channel() {
      const channelId = this.contacts?.channel
      switch (channelId) {
        case this.$const?.SEND_METHOD?.SMS?.COMMUNICATION_TYPE:
          return 'Смс'
        case this.$const?.SEND_METHOD?.TELEGRAM?.COMMUNICATION_TYPE:
          return 'Telegram'
        case this.$const?.SEND_METHOD?.WHATSAPP?.COMMUNICATION_TYPE:
          return 'WhatsApp'
        case this.$const?.SEND_METHOD?.VIBER?.COMMUNICATION_TYPE:
          return 'Viber'
      }
      return null
    },
    observedElement() {
      return this.$refs.controlls
    },
    allPositions() {
      return this['basket/allPositions']
    }
  },
  methods: {
    //...mapMutations('basket', [basketTypes.SET_BASKET]),
    // ...mapMutations('verificationCode', [verificationTypes.SET_CONTACTS]),
    ...mapMutations('basket', [basketTypes.ADD_CARDLOYALITY]),
    ...mapMutations('yookassa', [yookassaTypes.SET_YOOKASSA_PAYMENT]),
    ...mapActions('yookassa', [yookassaTypes.MAKE_PAYMENT_ACTION]),
    ...mapMutations('verificationCode', [
      verificationTypes.SET_EDIT_CONTACT_MODE
    ]),
    ...mapMutations('panelBurger', [panelBurgerTypes.TOGGLE_PANEL_BURGER]),
    ...mapMutations('app', [appTypes.SET_OPACITY]),
    ...mapMutations('navigation', [navTypes.SET_COMMAND]),
    togglePanelBurger() {
      this[panelBurgerTypes.TOGGLE_PANEL_BURGER]()
    },
    goOffer() {
      this[navTypes.SET_COMMAND](this.$const?.NAV_COMMANDS?.CLOSE_BURGER)
      this.togglePanelBurger()
      this.changePanelBurgerPage(OFFER)
    },
    editContacts() {
      this[verificationTypes.SET_EDIT_CONTACT_MODE](true)
      this.$router.push({ name: 'Contacts' })
    },
    pay() {
      if(this.loyaltyCard){
      this[basketTypes.ADD_CARDLOYALITY](+this.loyaltyCard)
      }
      const data = {
        amount: this.allPositions?.price,
        description: 'Описание платежа' // Описание платежа
      }
      this.loadingPayment = true
      this[yookassaTypes.MAKE_PAYMENT_ACTION](data).then(() => {
        this[appTypes.SET_OPACITY](0)
        setTimeout(() => {
          this.$router.push('/yookassa')
        }, 250)
      })
      // this.changePanelPage(SUCCESS_PAGE)
      // this.$router.push('/payment-result')
      // this[basketTypes.SET_BASKET](null)
      // this[verificationTypes.SET_CONTACTS]({
      //   email: null,
      //   phone: null,
      //   sendingMethod: null
      // })
    },
    makePayment() {
      const price = this.allPositions?.price
      if (price && price > 0) {
        const data = {
          amount: price, // Сумма платежа
          description: 'Оплата заказа № 1' // Описание платежа
        }
        this.loadingPayment = true
        axios
          .post('https://widget.mltest.site/yookassa/api/payment/', data)
          .then(x => {
            this[yookassaTypes.SET_YOOKASSA_PAYMENT](x.data)
            this.$router.push('/yookassa')
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        alert('Ошибка опеределения суммы оплаты')
      }
    }
  },
  mounted() {
    if (!this['verificationCode/isVerified']) {
      this.$router.push('/sending')
    }
    this.loadingPayment = false
    this.showNavs(true)
    this.handleScroll()
  }
}
</script>
